// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase:{
    apiKey: "AIzaSyAjExLpEF5mffFVkWVXs600Jgya94D7UCE",
    authDomain: "vidhemaems.firebaseapp.com",
    projectId: "vidhemaems",
    storageBucket: "vidhemaems.appspot.com",
    messagingSenderId: "872394287077",
    appId: "1:872394287077:web:6e4a2788c1f31d2180b10c",
    measurementId: "G-D2HJPQ208D"
  },
  APIPATH:{
    USER:'users',
    RATING:'rating',
    CLIENTS:'clients',
    PROJECTS:'projects',
    PROJECTMEDIAS:'projectmedia',
    PROJECTCREDENTIALS:'projectcredentials',
    SHEETS:"sheet",
    AUTHOR:"authors",
    BLOGS:"blogs",
    CATEGORY:"categories",
    ITHEHEMES:"iThemesSites",
    NEWSLETTER:"newsletters",
    PAYMENTS:"payments",
    PRICING:"pricings",
    PROMOTIONALWEBPAGE:"promotionalWebPages",
    VIDHEMA:"vidhemas",
    TASKS:"tasks",
    CAREERCATEGORY:"career/category",
    CAREERQUESTIONS:"career/question",
    CAREERJOBS:"career/jobs",
    CAREERFORMS:"career/form",
    CAREERAPPLICATIONS:"career/application",
    ATTENDANCE:"attendance",
    INTERVIEW:"interview",
    SqlUsers:'mysql-users',
    SqlDatabase:'mysql-database',
    SelectEntry:'hardware-information',
    HardwareCategories:'hardware-categories',
    Settiings:'settings',
    HrGuidelines: 'hr-guidelines',
    ServerPort: 'server-port',
    BulkEmail: 'bulk-email',
    EmailTemplate: 'email-template',

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
