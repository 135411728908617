<div class="bgimg">

<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content class="mat-typography">
	<mat-list role="list">
	  <mat-list-item *ngFor="let checklistObj of data.content">
     {{ checklistObj.title }}
     <button mat-icon-button mat-dialog-close (click)="goToEditChecklist(checklistObj.id)">
        <mat-icon>edit</mat-icon>
     </button>
  </mat-list-item>
	</mat-list>
</mat-dialog-content>
<mat-dialog-actions  align="end">
  <button mat-raised-button  mat-dialog-close>Close</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-raised-button  mat-dialog-close (click)="goToAddChecklist()">Add Step</button>
</mat-dialog-actions>
</div>