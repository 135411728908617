<h2 mat-dialog-title> Upload Image </h2>
<mat-dialog-content>
    <div>
        <div class="inputDiv" >
            <div class="col-md-12 col-sm-12">
            <input type="file" #fileInput id="fileInput" (change)="upload($event)" style="display: none;" >
                <ngx-file-drop (click)="fileInput.click()" dropZoneLabel="Drop files here" (onFileDrop)="dropped($event)" 
                    (onFileOver)="fileOver($event)" (onFileLeave)="fileLeave($event)">
                        <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                        Drop Files or click to open file selector
                    </ng-template>
                </ngx-file-drop>
              </div>
              <div class="col-md-12 col-sm-12 uploadButtonClass" *ngIf="selectedFile">
                <p> {{selectedFile?.name}} </p>  <button class="button btnup" (click)="uploadSelectedFile()">Upload</button>
              </div>

            <div *ngIf="uploadedFile">
                <span (click)="copyLink()" class="borderClassForLink" > 
                    <span class="text"> {{uploadedFile?.url}}</span> 
                    <span class="material-icons">
                        content_copy
                    </span> 
                </span>  
                <input type="text" style="opacity:0" value="{{uploadedFile?.url}}" id="myInput">
            </div>


        </div>
       
    </div>
</mat-dialog-content>
<mat-dialog-actions >
  <button class="button" (click)="cancel()">Cancel</button>
  <button class="button btnup" (click)="submit()">Fill in the Field</button>
</mat-dialog-actions>
