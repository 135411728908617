import { Country } from "./countries";

export class AppSettings {

  // public static API_ENDPOINT = "http://localhost:3006/";

  public static API_ENDPOINT = 'https://api.vidhema.com/';

  public static API_TIMEOUT = 3000; // 1 second Timeout

  public static LOGIN_ERROR = "Either username/password invalid. Please try again.";

  public static LOGOUT_SUCCESS = "Successfully Logout.";

  public static SITE_NAME = "Vidhema EMS";

  public static GENERIC_ERROR = "There is some error. Please try again.";

  public static DEFAULT_COURSE = "5c4645c20a3168054e04f915";

  public static BUCKETPATH = {
    CATEGORY: "CATEGORY",
  };

  public static ROLE = {
    ADMIN: "admin",
    USER: "user",
    INTERVIEW: "interview",
  };

  public static COUNTRIES_LIST = Country.ALL_COUNTRIES;

  public static MESSAGE = {
    USER: {
      ADD_TITLE: "Registerd Successfully",
      ADD:
        "Congratulations you have created an account. A confirmation email has been sent. To activate the Grocery, simply click on the word Verify in the email you received. Be sure to check your Clutter and Junk Email folders.",
      EDIT: "Employee Info has been updated",
      DELETE: "Employee has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Employee?",
      SIGNUP_ERR: "Email address already exist. Please try again.",
      FORGET_PASSWORD:
        "Reset Password link has been sent at your email address.",
      RESET_PASSWORD:
        "Congratulations! Your password has been reset successfully.",
      CHANGE_PASSWORD:
        "Congratulations! Your password has been changed successfully.",
    },
    INTERVIEW:{
      ADD:
        "Congratulations you have created an account. A confirmation email has been sent. To activate the Grocery, simply click on the word Verify in the email you received. Be sure to check your Clutter and Junk Email folders.",
      EDIT: "Interview Info has been updated",
      DELETE: "Interview has been deleted",
    },
    PRICING:{
      DELETE: "Pricing has been deleted",
      DELETE_CONFIRM: "Are you sure you want to delete this Pricing?",
    },
    LOGIN: {
      VERFY_ACCESS_CODE_ERR: "Access Code is invalid. Please try again.",
    },
  };

}
