import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ToastComponent } from './shared/toast/toast.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DialogComponent, DialogDataDialog, DialogListDialog, DialogDetailDialog, DialogContentDialog, DialogSuccessDialog, DialogInputDataDialog } from './shared/dialog/dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { PrivacyComponent } from './shared/privacy/privacy.component';
import { TermsComponent } from './shared/terms/terms.component';

import { environment } from 'src/environments/environment';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { UploadImageComponent } from './shared/upload-image/upload-image.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { InterviewmanagerComponent } from './interview/interviewmanager/interviewmanager.component';
// import { FilterdsrmodalComponent } from './filterdsrmodal/filterdsrmodal.component';
// import { DialogElementsComponent } from './dsr/dialog-elements/dialog-elements.component';
// import { DSRComponent } from './admin/dsr/dsr.component';
const config: SocketIoConfig = { url: 'http://localhost:3006', options: {
  transports: ['websocket']
} };
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    ToastComponent,
    DialogComponent,
    DialogDataDialog,
    DialogListDialog,
    DialogDetailDialog,
    DialogContentDialog,
    DialogSuccessDialog,
    DialogInputDataDialog,
    PrivacyComponent,
    TermsComponent,
    UploadImageComponent,
    InterviewmanagerComponent,
    // FilterdsrmodalComponent,
    // DialogElementsComponent,
    // DSRComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LottieModule.forRoot({ player: playerFactory,useCache: true, }),
    BrowserAnimationsModule,
    MatSnackBarModule,
    FormsModule,
    MatDialogModule,
    MatListModule,
    SocketIoModule.forRoot(config),
    MatIconModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgbModule,
    NgxFileDropModule,
  ],
  providers: [
    ToastComponent,
    DialogComponent,
    DialogDataDialog,
    DialogListDialog,
    DialogDetailDialog,
    DialogContentDialog,
    DialogSuccessDialog,
    DialogInputDataDialog,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
