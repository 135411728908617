<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p>{{data.content}}</p>
  <mat-form-field>
    <input matInput [(ngModel)]="data.inputdata">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>No Thanks</button>
  <button mat-button [mat-dialog-close]="data.inputdata" cdkFocusInitial>Ok</button>
</div>