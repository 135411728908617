import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  dropdown:boolean = false;
  dropdownTimer:boolean = false;
  searchFlag:boolean = false;
  loading:any = [];
  accessToken:any;
  userId:any;
  userData:any;
  allTasks:any;
  SheetData:any;
  calendarData:any;

  assignData:any = [];
  constructor() { }

  showLoading() {
    this.loading.push('1');
  }

  hideLoading() {
    this.loading.splice(0,1);
  }     
}
