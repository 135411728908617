<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content class="mat-typography">
  <div class="row">
      <div class="col-md-4 border"><strong>Title</strong></div>
      <div class="col-md-8 border">{{data.content.title}}</div>
    </div>
  <div class="row">
    
      <div class="col-md-4 border"><strong>Notes</strong></div>
      <div class="col-md-8 border" [innerHTML]="data.content.notes"></div>
    </div>
	<div class="row">
      <div class="col-md-4 border"><strong>Link</strong></div>
      <div class="col-md-8 border" [innerHTML]="data.content.link_url"></div>
  </div>
  <div class="row">
      <div class="col-md-4 border"><strong>Attachment</strong></div>
      <div class="col-md-8 border">
          <ul>
                           
              <li *ngFor="let fileObj of data.content.files">
                  <a href="{{fileObj.Location}}">{{fileObj.Key}}</a>
              </li>

          </ul>

      </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions  align="end">
  <button mat-raised-button  mat-dialog-close>Close</button>
</mat-dialog-actions>
