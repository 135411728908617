import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-interviewmanager',
  templateUrl: './interviewmanager.component.html',
  styleUrls: ['./interviewmanager.component.scss']
})
export class InterviewmanagerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
