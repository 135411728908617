import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { FileUploadService } from 'src/app/services/fileUpload/file-upload.service';
import { FileUpload } from '../classes/file-upload';
import { ToastComponent } from '../toast/toast.component';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss']
})
export class UploadImageComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];
  selectedFile:any;
  uploadedFile:any;
    constructor(
      public dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data: any,
      public FileUploadService:FileUploadService,
      private toast:ToastComponent,
      public dialogRef: MatDialogRef<UploadImageComponent>
    ) { 
      console.log(this.data);
   
    }
    cancel(){
      console.log("cancel");
      this.dialogRef.close();
    }
  
  
    submit(){
      console.log(this.data);
      this.dialogRef.close(this.uploadedFile.url);
    }
  
  ngOnInit(): void {
  }

  
  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {

      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          this.gotFiles(file);
        });
      } else {
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event){
    console.log(event);
  }

  public fileLeave(event){
    console.log(event);
  }
  upload(event){
    var file = event.target.files[0];
    this.gotFiles(file);
  }

  gotFiles(file){
    console.log(file);
    this.selectedFile = new FileUpload(file);
  }

  uploadSelectedFile(){
    console.log("upload",this.selectedFile);
    this.uploadImage();

  }
  deleteImage(){
    this.FileUploadService.deleteUpload(this.uploadedFile,'/VidhemaBlogImages/').then((res)=>{
      console.log(res);
      this.uploadedFile = undefined;
    }).catch((err)=>{
      console.log(err);
    })
  }

  uploadImage(){
    this.FileUploadService.pushUpload(this.selectedFile,'/VidhemaBlogImages/').then((res)=>{
      console.log(res);
      this.uploadedFile = res;
      this.selectedFile = undefined
    });
  }

  copyLink(){
    var copyText:any = document.getElementById("myInput");
    copyText.select();
    copyText.setSelectionRange(0, 99999)
    document.execCommand("copy");
    this.toast.toastSuccess('Copied to ClipBoard');
    }

}
