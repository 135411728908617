import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyComponent } from './shared/privacy/privacy.component';
import { TermsComponent } from './shared/terms/terms.component';


const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) 
  },
  {
    path: 'privacy',
    component:PrivacyComponent
  },
  {
    path: 'terms',
    component:TermsComponent
  },
  {
    path: '',
    redirectTo: '/admin',
    pathMatch : 'full'
  },
  {
    path: '**',
    redirectTo: '/admin',
     pathMatch : 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
