import { Injectable } from '@angular/core';
import { FileUpload } from 'src/app/shared/classes/file-upload';
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'src/app/core/constant';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { LoaderService } from '../loader/loader.service';
declare var AWS: any;

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  apiUrl: string = AppSettings.API_ENDPOINT;
  headers: any;

  loadingFlag: boolean = false;
  uploadPercentage: any = '';
  constructor(public http: HttpClient,
    public loadingService: LoaderService,
    public snackbarCmp: ToastComponent,
    public dialog: DialogComponent,) {

  }

  private basePath: string = '/';

  pushUpload(upload, subfolderPath: string) {
    console.log(upload, subfolderPath)
    let date = new Date();
    let name = '';
    name = name + date.getFullYear() + (date.getMonth() + 1) + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds() + '-' + date.getMilliseconds() + upload.file.name;
    let bucket = new AWS.S3(
      {
        accessKeyId: 'AKIARWTYEXZGV5I6UJQJ',
        secretAccessKey: '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',
        region: 'ap-south-1'
      }
    );

    const params = {
      Bucket: 'suribucket-v2',
      Key: 'Vidhema' + subfolderPath + name,
      Body: upload.file,
      ContentType: upload.type,
      ACL: "public-read-write"
    };

    return new Promise((resolve, reject) => {
      this.loadingFlag = true;
      let self = this;
      bucket.upload(params).on('httpUploadProgress', function (evt) {
        self.uploadPercentage = ((evt.loaded / evt.total) * 100).toFixed(0);;
        console.log(self.uploadPercentage)
        upload.progress = ((evt.loaded / evt.total) * 100).toFixed(0);
      }).send(function (err, data) {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          self.loadingFlag = false;
          reject(err);
        }
        if (data) {
          console.log('Successfully uploaded file.', data);
          resolve({ url: data.Location });
          self.loadingFlag = false;
        }
      });
    });
  }


  deleteUpload(upload: FileUpload, subfolderPath: string) {
    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }
  save(data: any) {
    console.log(data, 'service')
    this.loadingService.showLoading();
    let addUSerURl = this.apiUrl + 'projectmedia'
    return new Promise((resolve, reject) => {
      this.http.post(addUSerURl, data, { headers: this.headers }).subscribe((res: any) => {
        resolve(res)
        this.loadingService.hideLoading();
      }, (err) => {
        this.loadingService.hideLoading();
        this.snackbarCmp.toastError(err.error.message);
        reject(err)
      })
    })
  }

  get() {
    this.loadingService.showLoading();
    let getUserData = this.apiUrl + 'projectmedia';
    return new Promise((resolve, reject) => {
      this.http.get(getUserData, { headers: this.headers }).subscribe((res: any) => {
        resolve(res)
        this.loadingService.hideLoading();
      }, (err) => {
        this.loadingService.hideLoading();
        this.snackbarCmp.toastError(err.error.message);
        reject(err)
      })
    })
  }
  delete(id:any){
    console.log('username',id)
    this.loadingService.showLoading();
    let deleteUserurl=this.apiUrl+'projectmedia'+'/'+id
    return new Promise((resolve,reject)=>{
      this.http.delete(deleteUserurl,{headers:this.headers}).subscribe((res:any)=>{
      resolve(res)
      this.loadingService.hideLoading()
      },(err)=>{
        this.loadingService.hideLoading();
        this.snackbarCmp.toastError(err.error.message);
        reject(err)
      })
    })
  }

  // Firebase files must have unique names in their respective storage dir
  // So the name serves as a unique key
  private deleteFileStorage(name: string, subfolderPath: string) {
    let folderPath = this.basePath + subfolderPath;

    return new Promise((resolve, reject) => {
      resolve(true);
    });
  }


}
