export class Country {

  public static ALL_COUNTRIES = [ 

{ ISOCode: "AD", name: "Andorra", FlagCssClass: "flag-ad", InputMasking: "999 999", dial_code: "+376 ", IsShowCustomFlag: false },
{ ISOCode: "AE", name: "United Arab Emirates", FlagCssClass: "flag-ae", InputMasking: "999 999 9999", dial_code: "+971 ", IsShowCustomFlag: false },
{ ISOCode: "AF", name: "Afghanistan", FlagCssClass: "flag-af", InputMasking: "999 999 9999", dial_code: "+93 ", IsShowCustomFlag: false },
{ ISOCode: "AG", name: "Antigua and Barbuda", FlagCssClass: "flag-ag", InputMasking: "999-9999", dial_code: "+1 (268) ", IsShowCustomFlag: false },
{ ISOCode: "AI", name: "Anguilla", FlagCssClass: "flag-ai", InputMasking: "999-9999", dial_code: "+1 (264) ", IsShowCustomFlag: false },
{ ISOCode: "AL", name: "Albania", FlagCssClass: "flag-al", InputMasking: "999 999 9999", dial_code: "+355 ", IsShowCustomFlag: false },
{ ISOCode: "AM", name: "Armenia", FlagCssClass: "flag-am", InputMasking: "999 999999", dial_code: "+374 ", IsShowCustomFlag: false },
{ ISOCode: "AN", name: "Netherlands Antilles", FlagCssClass: "flag-an", InputMasking: "999 9999", dial_code: "+599 ", IsShowCustomFlag: false },
{ ISOCode: "AO", name: "Angola", FlagCssClass: "flag-ao", InputMasking: "999 999 999", dial_code: "+244 ", IsShowCustomFlag: false },
{ ISOCode: "AQ", name: "Antarctica", FlagCssClass: "flag-aq", InputMasking: "9 99999", dial_code: "+672 ", IsShowCustomFlag: false },
{ ISOCode: "AR", name: "Argentina", FlagCssClass: "flag-ar", InputMasking: "999 99-9999-9999", dial_code: "+54 ", IsShowCustomFlag: false },
{ ISOCode: "AS", name: "American Samoa", FlagCssClass: "flag-as", InputMasking: "999-9999", dial_code: "+1 (684) ", IsShowCustomFlag: false },
{ ISOCode: "AT", name: "Austria", FlagCssClass: "flag-at", InputMasking: "9999 999999", dial_code: "+43 ", IsShowCustomFlag: false },
{ ISOCode: "AU", name: "Australia", FlagCssClass: "flag-au", InputMasking: "9999 999 999", dial_code: "+61 ", IsShowCustomFlag: false },
{ ISOCode: "AW", name: "Aruba", FlagCssClass: "flag-aw", InputMasking: "999 9999", dial_code: "+297 ", IsShowCustomFlag: false },
{ ISOCode: "AZ", name: "Azerbaijan", FlagCssClass: "flag-az", InputMasking: "999 999 99 99", dial_code: "+994 ", IsShowCustomFlag: false },
{ ISOCode: "BA", name: "Bosnia and Herzegovina", FlagCssClass: "flag-ba", InputMasking: "999 999 999", dial_code: "+387 ", IsShowCustomFlag: false },
{ ISOCode: "BB", name: "Barbados", FlagCssClass: "flag-bb", InputMasking: "999-9999", dial_code: "+1 (246) ", IsShowCustomFlag: false },
{ ISOCode: "BD", name: "Bangladesh", FlagCssClass: "flag-bd", InputMasking: "99999-999999", dial_code: "+880 ", IsShowCustomFlag: false },
{ ISOCode: "BE", name: "Belgium", FlagCssClass: "flag-be", InputMasking: "9999 99 99 99", dial_code: "+32 ", IsShowCustomFlag: false },
{ ISOCode: "BF", name: "Burkina Faso", FlagCssClass: "flag-bf", InputMasking: "99 99 99 99", dial_code: "+226 ", IsShowCustomFlag: false },
{ ISOCode: "BG", name: "Bulgaria", FlagCssClass: "flag-bg", InputMasking: "999 999 999", dial_code: "+359 ", IsShowCustomFlag: false },
{ ISOCode: "BH", name: "Bahrain", FlagCssClass: "flag-bh", InputMasking: "9999 9999", dial_code: "+973 ", IsShowCustomFlag: false },
{ ISOCode: "BI", name: "Burundi", FlagCssClass: "flag-bi", InputMasking: "99 99 99 99", dial_code: "+257 ", IsShowCustomFlag: false },
{ ISOCode: "BJ", name: "Benin", FlagCssClass: "flag-bj", InputMasking: "99 99 99 99", dial_code: "+229 ", IsShowCustomFlag: false },
{ ISOCode: "BL", name: "Saint Barthelemy", FlagCssClass: "flag-bl", InputMasking: "9999 99 99 99", dial_code: "+590 ", IsShowCustomFlag: false },
{ ISOCode: "BM", name: "Bermuda", FlagCssClass: "flag-bm", InputMasking: "999-9999", dial_code: "+1 (441) ", IsShowCustomFlag: false },
{ ISOCode: "BN", name: "Brunei", FlagCssClass: "flag-bn", InputMasking: "999 9999", dial_code: "+673 ", IsShowCustomFlag: false },
{ ISOCode: "BO", name: "Bolivia", FlagCssClass: "flag-bo", InputMasking: "99999999", dial_code: "+591 ", IsShowCustomFlag: false },
{ ISOCode: "BR", name: "Brazil", FlagCssClass: "flag-br", InputMasking: "(99) 99999-9999", dial_code: "+55 ", IsShowCustomFlag: false },
{ ISOCode: "BS", name: "Bahamas", FlagCssClass: "flag-bs", InputMasking: "999-9999", dial_code: "+1 (242) ", IsShowCustomFlag: false },
{ ISOCode: "BT", name: "Bhutan", FlagCssClass: "flag-bt", InputMasking: "99 99 99 99", dial_code: "+975 ", IsShowCustomFlag: false },
{ ISOCode: "BW", name: "Botswana", FlagCssClass: "flag-bw", InputMasking: "99 999 999", dial_code: "+267 ", IsShowCustomFlag: false },
{ ISOCode: "BY", name: "Belarus", FlagCssClass: "flag-by", InputMasking: "9 999 999-99-99", dial_code: "+375 ", IsShowCustomFlag: false },
{ ISOCode: "BZ", name: "Belize", FlagCssClass: "flag-bz", InputMasking: "999-9999", dial_code: "+501 ", IsShowCustomFlag: false },
{ ISOCode: "CA", name: "Canada", FlagCssClass: "flag-ca", InputMasking: "(999) 999-9999", dial_code: "+1 ", IsShowCustomFlag: false },
{ ISOCode: "CC", name: "Cocos Islands", FlagCssClass: "flag-cc", InputMasking: "9999 999 999", dial_code: "+61 ", IsShowCustomFlag: false },
{ ISOCode: "CD", name: "Democratic Republic of the Congo", FlagCssClass: "flag-cd", InputMasking: "9999 999 999", dial_code: "+243 ", IsShowCustomFlag: false },
{ ISOCode: "CF", name: "Central African Republic", FlagCssClass: "flag-cf", InputMasking: "99 99 99 99", dial_code: "+236 ", IsShowCustomFlag: false },
{ ISOCode: "CG", name: "Republic of the Congo", FlagCssClass: "flag-cg", InputMasking: " 99 999 9999", dial_code: "+242 ", IsShowCustomFlag: false },
{ ISOCode: "CH", name: "Switzerland", FlagCssClass: "flag-ch", InputMasking: "999 999 99 99", dial_code: "+41 ", IsShowCustomFlag: false },
{ ISOCode: "CI", name: "Ivory Coast", FlagCssClass: "flag-ci", InputMasking: "99 99 99 99", dial_code: "+225 ", IsShowCustomFlag: false },
{ ISOCode: "CK", name: "Cook Islands", FlagCssClass: "flag-ck", InputMasking: "99 9999", dial_code: "+682 ", IsShowCustomFlag: false },
{ ISOCode: "CL", name: "Chile", FlagCssClass: "flag-cl", InputMasking: "9 9999 9999", dial_code: "+56 ", IsShowCustomFlag: false },
{ ISOCode: "CM", name: "Cameroon", FlagCssClass: "flag-cm", InputMasking: "9 99 99 99 99", dial_code: "+237 ", IsShowCustomFlag: false },
{ ISOCode: "CN", name: "China", FlagCssClass: "flag-cn", InputMasking: "999 9999 9999", dial_code: "+86 ", IsShowCustomFlag: false },
{ ISOCode: "CO", name: "Colombia", FlagCssClass: "flag-co", InputMasking: "999 9999999", dial_code: "+57 ", IsShowCustomFlag: false },
{ ISOCode: "CR", name: "Costa Rica", FlagCssClass: "flag-cr", InputMasking: "9999 9999", dial_code: "+506 ", IsShowCustomFlag: false },
{ ISOCode: "CU", name: "Cuba", FlagCssClass: "flag-cu", InputMasking: "99 9999999", dial_code: "+53 ", IsShowCustomFlag: false },
{ ISOCode: "CV", name: "Cape Verde", FlagCssClass: "flag-cv", InputMasking: "999 99 99", dial_code: "+238 ", IsShowCustomFlag: false },
{ ISOCode: "CW", name: "Curacao", FlagCssClass: "flag-cw", InputMasking: "999 9999", dial_code: "+599 ", IsShowCustomFlag: false },
{ ISOCode: "CX", name: "Christmas Island", FlagCssClass: "flag-cx", InputMasking: "9999 999 999", dial_code: "+61 ", IsShowCustomFlag: false },
{ ISOCode: "CY", name: "Cyprus", FlagCssClass: "flag-cy", InputMasking: "99 999999", dial_code: "+357 ", IsShowCustomFlag: false },
{ ISOCode: "CZ", name: "Czech Republic", FlagCssClass: "flag-cz", InputMasking: "999 999 999", dial_code: "+420 ", IsShowCustomFlag: false },
{ ISOCode: "DE", name: "Germany", FlagCssClass: "flag-de", InputMasking: "99999 9999999", dial_code: "+49 ", IsShowCustomFlag: false },
{ ISOCode: "DJ", name: "Djibouti", FlagCssClass: "flag-dj", InputMasking: "99 99 99 99", dial_code: "+253 ", IsShowCustomFlag: false },
{ ISOCode: "DK", name: "Denmark", FlagCssClass: "flag-dk", InputMasking: "99 99 99 99", dial_code: "+45 ", IsShowCustomFlag: false },
{ ISOCode: "DM", name: "Dominica", FlagCssClass: "flag-dm", InputMasking: "999-9999", dial_code: "+1 (767) ", IsShowCustomFlag: false },
{ ISOCode: "DO", name: "Dominican Republic", FlagCssClass: "flag-do", InputMasking: "999-9999", dial_code: "+1 (809) ", IsShowCustomFlag: false },
{ ISOCode: "DZ", name: "Algeria", FlagCssClass: "flag-dz", InputMasking: "9999 99 99 99", dial_code: "+213 ", IsShowCustomFlag: false },
{ ISOCode: "EC", name: "Ecuador", FlagCssClass: "flag-ec", InputMasking: "999 999 9999", dial_code: "+593 ", IsShowCustomFlag: false },
{ ISOCode: "EE", name: "Estonia", FlagCssClass: "flag-ee", InputMasking: "9999 9999", dial_code: "+372 ", IsShowCustomFlag: false },
{ ISOCode: "EG", name: "Egypt", FlagCssClass: "flag-eg", InputMasking: "9999 999 9999", dial_code: "+20 ", IsShowCustomFlag: false },
{ ISOCode: "EH", name: "Western Sahara", FlagCssClass: "flag-eh", InputMasking: "9999-999999", dial_code: "+212 ", IsShowCustomFlag: false },
{ ISOCode: "ER", name: "Eritrea", FlagCssClass: "flag-er", InputMasking: "99 999 999", dial_code: "+291 ", IsShowCustomFlag: false },
{ ISOCode: "ES", name: "Spain", FlagCssClass: "flag-es", InputMasking: "999 99 99 99", dial_code: "+34 ", IsShowCustomFlag: false },
{ ISOCode: "ET", name: "Ethiopia", FlagCssClass: "flag-et", InputMasking: "999 999 9999", dial_code: "+251 ", IsShowCustomFlag: false },
{ ISOCode: "FI", name: "Finland", FlagCssClass: "flag-fi", InputMasking: "999 9999999", dial_code: "+358 ", IsShowCustomFlag: false },
{ ISOCode: "FJ", name: "Fiji", FlagCssClass: "flag-fj", InputMasking: "999 9999", dial_code: "+679 ", IsShowCustomFlag: false },
{ ISOCode: "FK", name: "Falkland Islands", FlagCssClass: "flag-fk", InputMasking: "99999", dial_code: "+500 ", IsShowCustomFlag: false },
{ ISOCode: "FM", name: "Micronesia", FlagCssClass: "flag-fm", InputMasking: "999 9999", dial_code: "+691 ", IsShowCustomFlag: false },
{ ISOCode: "FO", name: "Faroe Islands", FlagCssClass: "flag-fo", InputMasking: "999999", dial_code: "+298 ", IsShowCustomFlag: false },
{ ISOCode: "FR", name: "France", FlagCssClass: "flag-fr", InputMasking: "99 99 99 99 99", dial_code: "+33 ", IsShowCustomFlag: false },
{ ISOCode: "GA", name: "Gabon", FlagCssClass: "flag-ga", InputMasking: "99 99 99 99", dial_code: "+241 ", IsShowCustomFlag: false },
{ ISOCode: "GB", name: "United Kingdom", FlagCssClass: "flag-gb", InputMasking: "99999 99999", dial_code: "+44 ", IsShowCustomFlag: false },
{ ISOCode: "GD", name: "Grenada", FlagCssClass: "flag-gd", InputMasking: "999-9999", dial_code: "+1 (473) ", IsShowCustomFlag: false },
{ ISOCode: "GE", name: "Georgia", FlagCssClass: "flag-ge", InputMasking: "999 99 99 99", dial_code: "+995 ", IsShowCustomFlag: false },
{ ISOCode: "GG", name: "Guernsey", FlagCssClass: "flag-gg", InputMasking: "99999 999999", dial_code: "+44 ", IsShowCustomFlag: false },
{ ISOCode: "GH", name: "Ghana", FlagCssClass: "flag-gh", InputMasking: "999 999 9999", dial_code: "+233 ", IsShowCustomFlag: false },
{ ISOCode: "GI", name: "Gibraltar", FlagCssClass: "flag-gi", InputMasking: "99999999", dial_code: "+350 ", IsShowCustomFlag: false },
{ ISOCode: "GL", name: "Greenland", FlagCssClass: "flag-gl", InputMasking: "99 99 99", dial_code: "+299 ", IsShowCustomFlag: false },
{ ISOCode: "GM", name: "Gambia", FlagCssClass: "flag-gm", InputMasking: "999 9999", dial_code: "+220 ", IsShowCustomFlag: false },
{ ISOCode: "GN", name: "Guinea", FlagCssClass: "flag-gn", InputMasking: "999 99 99 99", dial_code: "+224 ", IsShowCustomFlag: false },
{ ISOCode: "GQ", name: "Equatorial Guinea", FlagCssClass: "flag-gq", InputMasking: "999 999 999", dial_code: "+240 ", IsShowCustomFlag: false },
{ ISOCode: "GR", name: "Greece", FlagCssClass: "flag-gr", InputMasking: "999 999 9999", dial_code: "+30 ", IsShowCustomFlag: false },
{ ISOCode: "GT", name: "Guatemala", FlagCssClass: "flag-gt", InputMasking: "9999 9999", dial_code: "+502 ", IsShowCustomFlag: false },
{ ISOCode: "GU", name: "Guam", FlagCssClass: "flag-gu", InputMasking: "999-9999", dial_code: "+1 (671) ", IsShowCustomFlag: false },
{ ISOCode: "GW", name: "Guinea-Bissau", FlagCssClass: "flag-gw", InputMasking: "999 999 999", dial_code: "+245 ", IsShowCustomFlag: false },
{ ISOCode: "GY", name: "Guyana", FlagCssClass: "flag-gy", InputMasking: "999 9999", dial_code: "+592 ", IsShowCustomFlag: false },
{ ISOCode: "HK", name: "Hong Kong", FlagCssClass: "flag-hk", InputMasking: "9999 9999", dial_code: "+852 ", IsShowCustomFlag: false },
{ ISOCode: "HN", name: "Honduras", FlagCssClass: "flag-hn", InputMasking: "9999-9999", dial_code: "+504 ", IsShowCustomFlag: false },
{ ISOCode: "HR", name: "Croatia", FlagCssClass: "flag-hr", InputMasking: "999 999 9999", dial_code: "+385 ", IsShowCustomFlag: false },
{ ISOCode: "HT", name: "Haiti", FlagCssClass: "flag-ht", InputMasking: "99 99 9999", dial_code: "+509 ", IsShowCustomFlag: false },
{ ISOCode: "HU", name: "Hungary", FlagCssClass: "flag-hu", InputMasking: "(99) 999 9999", dial_code: "+36 ", IsShowCustomFlag: false },
{ ISOCode: "ID", name: "Indonesia", FlagCssClass: "flag-id", InputMasking: "9999-999-999", dial_code: "+62 ", IsShowCustomFlag: false },
{ ISOCode: "IE", name: "Ireland", FlagCssClass: "flag-ie", InputMasking: "999 999 9999", dial_code: "+353 ", IsShowCustomFlag: false },
{ ISOCode: "IL", name: "Israel", FlagCssClass: "flag-il", InputMasking: "999-999-9999", dial_code: "+972 ", IsShowCustomFlag: false },
{ ISOCode: "IM", name: "Isle of Man", FlagCssClass: "flag-im", InputMasking: "99999 999999", dial_code: "+44 ", IsShowCustomFlag: false },
{ ISOCode: "IN", name: "India", FlagCssClass: "flag-in", InputMasking: "99999 99999", dial_code: "+91 ", IsShowCustomFlag: false },
{ ISOCode: "IO", name: "British Indian Ocean Territory", FlagCssClass: "flag-io", InputMasking: "999 9999", dial_code: "+246 ", IsShowCustomFlag: false },
{ ISOCode: "IQ", name: "Iraq", FlagCssClass: "flag-iq", InputMasking: "9999 999 9999", dial_code: "+964 ", IsShowCustomFlag: false },
{ ISOCode: "IR", name: "Iran", FlagCssClass: "flag-ir", InputMasking: "9999 999 9999", dial_code: "+98 ", IsShowCustomFlag: false },
{ ISOCode: "IS", name: "Iceland", FlagCssClass: "flag-is", InputMasking: "999 9999", dial_code: "+354 ", IsShowCustomFlag: false },
{ ISOCode: "IT", name: "Italy", FlagCssClass: "flag-it", InputMasking: "999 999 9999", dial_code: "+39 ", IsShowCustomFlag: false },
{ ISOCode: "JE", name: "Jersey", FlagCssClass: "flag-je", InputMasking: "99999 999999", dial_code: "+44 ", IsShowCustomFlag: false },
{ ISOCode: "JM", name: "Jamaica", FlagCssClass: "flag-jm", InputMasking: "999-9999", dial_code: "+1 (876) ", IsShowCustomFlag: false },
{ ISOCode: "JO", name: "Jordan", FlagCssClass: "flag-jo", InputMasking: "99 9999 9999", dial_code: "+962 ", IsShowCustomFlag: false },
{ ISOCode: "JP", name: "Japan", FlagCssClass: "flag-jp", InputMasking: "999 9999-9999", dial_code: "+81 ", IsShowCustomFlag: false },
{ ISOCode: "KE", name: "Kenya", FlagCssClass: "flag-ke", InputMasking: "9999 999999", dial_code: "+254 ", IsShowCustomFlag: false },
{ ISOCode: "KG", name: "Kyrgyzstan", FlagCssClass: "flag-kg", InputMasking: "9999 999 999", dial_code: "+996 ", IsShowCustomFlag: false },
{ ISOCode: "KH", name: "Cambodia", FlagCssClass: "flag-kh", InputMasking: "999 999 999", dial_code: "+855 ", IsShowCustomFlag: false },
{ ISOCode: "KI", name: "Kiribati", FlagCssClass: "flag-ki", InputMasking: "99999999", dial_code: "+686 ", IsShowCustomFlag: false },
{ ISOCode: "KM", name: "Comoros", FlagCssClass: "flag-km", InputMasking: "999 99 99", dial_code: "+269 ", IsShowCustomFlag: false },
{ ISOCode: "KN", name: "Saint Kitts and Nevis", FlagCssClass: "flag-kn", InputMasking: "999-9999", dial_code: "+1 (869) ", IsShowCustomFlag: false },
{ ISOCode: "KP", name: "North Korea", FlagCssClass: "flag-kp", InputMasking: "9999 999 9999", dial_code: "+850 ", IsShowCustomFlag: false },
{ ISOCode: "KR", name: "South Korea", FlagCssClass: "flag-kr", InputMasking: "999-9999-9999", dial_code: "+82 ", IsShowCustomFlag: false },
{ ISOCode: "KW", name: "Kuwait", FlagCssClass: "flag-kw", InputMasking: "999 99999", dial_code: "+965 ", IsShowCustomFlag: false },
{ ISOCode: "KY", name: "Cayman Islands", FlagCssClass: "flag-ky", InputMasking: "999-9999", dial_code: "+1 (345) ", IsShowCustomFlag: false },
{ ISOCode: "KZ", name: "Kazakhstan", FlagCssClass: "flag-kz", InputMasking: "9 (999) 999 9999", dial_code: "+7 ", IsShowCustomFlag: false },
{ ISOCode: "LA", name: "Laos", FlagCssClass: "flag-la", InputMasking: "999 99 999 999", dial_code: "+856 ", IsShowCustomFlag: false },
{ ISOCode: "LB", name: "Lebanon", FlagCssClass: "flag-lb", InputMasking: "99 999 999", dial_code: "+961 ", IsShowCustomFlag: false },
{ ISOCode: "LC", name: "Saint Lucia", FlagCssClass: "flag-lc", InputMasking: "999-9999", dial_code: "+1 (758) ", IsShowCustomFlag: false },
{ ISOCode: "LI", name: "Liechtenstein", FlagCssClass: "flag-li", InputMasking: "999 999 999", dial_code: "+423 ", IsShowCustomFlag: false },
{ ISOCode: "LK", name: "Sri Lanka", FlagCssClass: "flag-lk", InputMasking: "999 999 9999", dial_code: "+94 ", IsShowCustomFlag: false },
{ ISOCode: "LR", name: "Liberia", FlagCssClass: "flag-lr", InputMasking: "999 999 9999", dial_code: "+231 ", IsShowCustomFlag: false },
{ ISOCode: "LS", name: "Lesotho", FlagCssClass: "flag-ls", InputMasking: "9999 9999", dial_code: "+266 ", IsShowCustomFlag: false },
{ ISOCode: "LT", name: "Lithuania", FlagCssClass: "flag-lt", InputMasking: "(9-999) 99999", dial_code: "+370 ", IsShowCustomFlag: false },
{ ISOCode: "LU", name: "Luxembourg", FlagCssClass: "flag-lu", InputMasking: "999 999 999", dial_code: "+352 ", IsShowCustomFlag: false },
{ ISOCode: "LV", name: "Latvia", FlagCssClass: "flag-lv", InputMasking: "99 999 999", dial_code: "+371 ", IsShowCustomFlag: false },
{ ISOCode: "LY", name: "Libya", FlagCssClass: "flag-ly", InputMasking: "999-9999999", dial_code: "+218 ", IsShowCustomFlag: false },
{ ISOCode: "MA", name: "Morocco", FlagCssClass: "flag-ma", InputMasking: "9999-999999", dial_code: "+212 ", IsShowCustomFlag: false },
{ ISOCode: "MC", name: "Monaco", FlagCssClass: "flag-mc", InputMasking: "99 99 99 99 99", dial_code: "+377 ", IsShowCustomFlag: false },
{ ISOCode: "MD", name: "Moldova", FlagCssClass: "flag-md", InputMasking: "9999 99 999", dial_code: "+373 ", IsShowCustomFlag: false },
{ ISOCode: "ME", name: "Montenegro", FlagCssClass: "flag-me", InputMasking: "999 999 999", dial_code: "+382 ", IsShowCustomFlag: false },
{ ISOCode: "MF", name: "Saint Martin", FlagCssClass: "flag-mf", InputMasking: "9999 99 99 99", dial_code: "+590 ", IsShowCustomFlag: false },
{ ISOCode: "MG", name: "Madagascar", FlagCssClass: "flag-mg", InputMasking: "999 99 999 99", dial_code: "+261 ", IsShowCustomFlag: false },
{ ISOCode: "MH", name: "Marshall Islands", FlagCssClass: "flag-mh", InputMasking: "999-9999", dial_code: "+692 ", IsShowCustomFlag: false },
{ ISOCode: "MK", name: "Macedonia", FlagCssClass: "flag-mk", InputMasking: "999 999 999", dial_code: "+389 ", IsShowCustomFlag: false },
{ ISOCode: "ML", name: "Mali", FlagCssClass: "flag-ml", InputMasking: "99 99 99 99", dial_code: "+223 ", IsShowCustomFlag: false },
{ ISOCode: "MM", name: "Myanmar", FlagCssClass: "flag-mm", InputMasking: "99 999 9999", dial_code: "+95 ", IsShowCustomFlag: false },
{ ISOCode: "MN", name: "Mongolia", FlagCssClass: "flag-mn", InputMasking: "9999 9999", dial_code: "+976 ", IsShowCustomFlag: false },
{ ISOCode: "MO", name: "Macau", FlagCssClass: "flag-mo", InputMasking: "9999 9999", dial_code: "+853 ", IsShowCustomFlag: false },
{ ISOCode: "MP", name: "Northern Mariana Islands", FlagCssClass: "flag-mp", InputMasking: "999-9999", dial_code: "+1 (670) ", IsShowCustomFlag: false },
{ ISOCode: "MR", name: "Mauritania", FlagCssClass: "flag-mr", InputMasking: "99 99 99 99", dial_code: "+222 ", IsShowCustomFlag: false },
{ ISOCode: "MS", name: "Montserrat", FlagCssClass: "flag-ms", InputMasking: "999-9999", dial_code: "+1 (664) ", IsShowCustomFlag: false },
{ ISOCode: "MT", name: "Malta", FlagCssClass: "flag-mt", InputMasking: "9999 9999", dial_code: "+356 ", IsShowCustomFlag: false },
{ ISOCode: "MU", name: "Mauritius", FlagCssClass: "flag-mu", InputMasking: "9999 9999", dial_code: "+230 ", IsShowCustomFlag: false },
{ ISOCode: "MV", name: "Maldives", FlagCssClass: "flag-mv", InputMasking: "999-9999", dial_code: "+960 ", IsShowCustomFlag: false },
{ ISOCode: "MW", name: "Malawi", FlagCssClass: "flag-mw", InputMasking: "9999 99 99 99", dial_code: "+265 ", IsShowCustomFlag: false },
{ ISOCode: "MX", name: "Mexico", FlagCssClass: "flag-mx", InputMasking: "999 999 999 9999", dial_code: "+52 ", IsShowCustomFlag: false },
{ ISOCode: "MY", name: "Malaysia", FlagCssClass: "flag-my", InputMasking: "999-999 9999", dial_code: "+60 ", IsShowCustomFlag: false },
{ ISOCode: "MZ", name: "Mozambique", FlagCssClass: "flag-mz", InputMasking: "99 999 9999", dial_code: "+258 ", IsShowCustomFlag: false },
{ ISOCode: "NA", name: "Namibia", FlagCssClass: "flag-na", InputMasking: "999 999 9999", dial_code: "+264 ", IsShowCustomFlag: false },
{ ISOCode: "NC", name: "New Caledonia", FlagCssClass: "flag-nc", InputMasking: "99 99 99", dial_code: "+687 ", IsShowCustomFlag: false },
{ ISOCode: "NE", name: "Niger", FlagCssClass: "flag-ne", InputMasking: "99 99 99 99", dial_code: "+227 ", IsShowCustomFlag: false },
{ ISOCode: "NG", name: "Nigeria", FlagCssClass: "flag-ng", InputMasking: "9999 999 9999", dial_code: "+234 ", IsShowCustomFlag: false },
{ ISOCode: "NI", name: "Nicaragua", FlagCssClass: "flag-ni", InputMasking: "9999 9999", dial_code: "+505 ", IsShowCustomFlag: false },
{ ISOCode: "NL", name: "Netherlands", FlagCssClass: "flag-nl", InputMasking: "99 99999999", dial_code: "+31 ", IsShowCustomFlag: false },
{ ISOCode: "NO", name: "Norway", FlagCssClass: "flag-no", InputMasking: "999 99 999", dial_code: "+47 ", IsShowCustomFlag: false },
{ ISOCode: "NP", name: "Nepal", FlagCssClass: "flag-np", InputMasking: "999-9999999", dial_code: "+977 ", IsShowCustomFlag: false },
{ ISOCode: "NR", name: "Nauru", FlagCssClass: "flag-nr", InputMasking: "999 9999", dial_code: "+674 ", IsShowCustomFlag: false },
{ ISOCode: "NU", name: "Niue", FlagCssClass: "flag-nu", InputMasking: "999 9999", dial_code: "+683 ", IsShowCustomFlag: false },
{ ISOCode: "NZ", name: "New Zealand", FlagCssClass: "flag-nz", InputMasking: "999 999 9999", dial_code: "+64 ", IsShowCustomFlag: false },
{ ISOCode: "OM", name: "Oman", FlagCssClass: "flag-om", InputMasking: "9999 9999", dial_code: "+968 ", IsShowCustomFlag: false },
{ ISOCode: "PA", name: "Panama", FlagCssClass: "flag-pa", InputMasking: "9999-9999", dial_code: "+507 ", IsShowCustomFlag: false },
{ ISOCode: "PE", name: "Peru", FlagCssClass: "flag-pe", InputMasking: "999 999 999", dial_code: "+51 ", IsShowCustomFlag: false },
{ ISOCode: "PF", name: "French Polynesia", FlagCssClass: "flag-pf", InputMasking: "99 99 99 99", dial_code: "+689 ", IsShowCustomFlag: false },
{ ISOCode: "PG", name: "Papua New Guinea", FlagCssClass: "flag-pg", InputMasking: "9999 9999", dial_code: "+675 ", IsShowCustomFlag: false },
{ ISOCode: "PH", name: "Philippines", FlagCssClass: "flag-ph", InputMasking: "9999 999 9999", dial_code: "+63 ", IsShowCustomFlag: false },
{ ISOCode: "PK", name: "Pakistan", FlagCssClass: "flag-pk", InputMasking: "9999 9999999", dial_code: "+92 ", IsShowCustomFlag: false },
{ ISOCode: "PL", name: "Poland", FlagCssClass: "flag-pl", InputMasking: "999 999 999", dial_code: "+48 ", IsShowCustomFlag: false },
{ ISOCode: "PM", name: "Saint Pierre and Miquelon", FlagCssClass: "flag-pm", InputMasking: "999 99 99", dial_code: "+508 ", IsShowCustomFlag: false },
{ ISOCode: "PN", name: "Pitcairn", FlagCssClass: "flag-pn", InputMasking: "99 999999", dial_code: "+64 ", IsShowCustomFlag: false },
{ ISOCode: "PR", name: "Puerto Rico", FlagCssClass: "flag-pr", InputMasking: "999-9999", dial_code: "+1 (787) ", IsShowCustomFlag: false },
{ ISOCode: "PS", name: "Palestine", FlagCssClass: "flag-ps", InputMasking: "9999 999 999", dial_code: "+970 ", IsShowCustomFlag: false },
{ ISOCode: "PT", name: "Portugal", FlagCssClass: "flag-pt", InputMasking: "999 999 999", dial_code: "+351 ", IsShowCustomFlag: false },
{ ISOCode: "PW", name: "Palau", FlagCssClass: "flag-pw", InputMasking: "999 9999", dial_code: "+680 ", IsShowCustomFlag: false },
{ ISOCode: "PY", name: "Paraguay", FlagCssClass: "flag-py", InputMasking: "9999 999999", dial_code: "+595 ", IsShowCustomFlag: false },
{ ISOCode: "QA", name: "Qatar", FlagCssClass: "flag-qa", InputMasking: "9999 9999", dial_code: "+974 ", IsShowCustomFlag: false },
{ ISOCode: "RE", name: "Reunion", FlagCssClass: "flag-re", InputMasking: "9999 99 99 99", dial_code: "+262 ", IsShowCustomFlag: false },
{ ISOCode: "RO", name: "Romania", FlagCssClass: "flag-ro", InputMasking: "9999 999 999", dial_code: "+40 ", IsShowCustomFlag: false },
{ ISOCode: "RS", name: "Serbia", FlagCssClass: "flag-rs", InputMasking: "999 9999999", dial_code: "+381 ", IsShowCustomFlag: false },
{ ISOCode: "RU", name: "Russia", FlagCssClass: "flag-ru", InputMasking: "9 (999) 999-99-99", dial_code: "+7 ", IsShowCustomFlag: false },
{ ISOCode: "RW", name: "Rwanda", FlagCssClass: "flag-rw", InputMasking: "9999 999 999", dial_code: "+250 ", IsShowCustomFlag: false },
{ ISOCode: "SA", name: "Saudi Arabia", FlagCssClass: "flag-sa", InputMasking: "999 999 9999", dial_code: "+966 ", IsShowCustomFlag: false },
{ ISOCode: "SB", name: "Solomon Islands", FlagCssClass: "flag-sb", InputMasking: "99 99999", dial_code: "+677 ", IsShowCustomFlag: false },
{ ISOCode: "SC", name: "Seychelles", FlagCssClass: "flag-sc", InputMasking: "9 999 999", dial_code: "+248 ", IsShowCustomFlag: false },
{ ISOCode: "SD", name: "Sudan", FlagCssClass: "flag-sd", InputMasking: "999 999 9999", dial_code: "+249 ", IsShowCustomFlag: false },
{ ISOCode: "SE", name: "Sweden", FlagCssClass: "flag-se", InputMasking: "999-999 99 99", dial_code: "+46 ", IsShowCustomFlag: false },
{ ISOCode: "SG", name: "Singapore", FlagCssClass: "flag-sg", InputMasking: "9999 9999", dial_code: "+65 ", IsShowCustomFlag: false },
{ ISOCode: "SH", name: "Saint Helena", FlagCssClass: "flag-sh", InputMasking: "99999", dial_code: "+290 ", IsShowCustomFlag: false },
{ ISOCode: "SI", name: "Slovenia", FlagCssClass: "flag-si", InputMasking: "999 999 999", dial_code: "+386 ", IsShowCustomFlag: false },
{ ISOCode: "SJ", name: "Svalbard and Jan Mayen", FlagCssClass: "flag-sj", InputMasking: "999 99 999", dial_code: "+47 ", IsShowCustomFlag: false },
{ ISOCode: "SK", name: "Slovakia", FlagCssClass: "flag-sk", InputMasking: "9999 999 999", dial_code: "+421 ", IsShowCustomFlag: false },
{ ISOCode: "SL", name: "Sierra Leone", FlagCssClass: "flag-sl", InputMasking: "(999) 999999", dial_code: "+232 ", IsShowCustomFlag: false },
{ ISOCode: "SM", name: "San Marino", FlagCssClass: "flag-sm", InputMasking: "99 99 99 99", dial_code: "+378 ", IsShowCustomFlag: false },
{ ISOCode: "SN", name: "Senegal", FlagCssClass: "flag-sn", InputMasking: "99 999 99 99", dial_code: "+221 ", IsShowCustomFlag: false },
{ ISOCode: "SO", name: "Somalia", FlagCssClass: "flag-so", InputMasking: "9 9999999", dial_code: "+252 ", IsShowCustomFlag: false },
{ ISOCode: "SR", name: "Suriname", FlagCssClass: "flag-sr", InputMasking: "999-9999", dial_code: "+597 ", IsShowCustomFlag: false },
{ ISOCode: "SS", name: "South Sudan", FlagCssClass: "flag-ss", InputMasking: "9999 999 999", dial_code: "+211 ", IsShowCustomFlag: false },
{ ISOCode: "ST", name: "Sao Tome and Principe", FlagCssClass: "flag-st", InputMasking: "999 9999", dial_code: "+239 ", IsShowCustomFlag: false },
{ ISOCode: "SV", name: "El Salvador", FlagCssClass: "flag-sv", InputMasking: "9999 9999", dial_code: "+503 ", IsShowCustomFlag: false },
{ ISOCode: "SX", name: "Sint Maarten", FlagCssClass: "flag-sx", InputMasking: "999-9999", dial_code: "+1 (721) ", IsShowCustomFlag: false },
{ ISOCode: "SY", name: "Syria", FlagCssClass: "flag-sy", InputMasking: "9999 999 999", dial_code: "+963 ", IsShowCustomFlag: false },
{ ISOCode: "SZ", name: "Swaziland", FlagCssClass: "flag-sz", InputMasking: "9999 9999", dial_code: "+268 ", IsShowCustomFlag: false },
{ ISOCode: "TC", name: "Turks and Caicos Islands", FlagCssClass: "flag-tc", InputMasking: "999-9999", dial_code: "+1 (649) ", IsShowCustomFlag: false },
{ ISOCode: "TD", name: "Chad", FlagCssClass: "flag-td", InputMasking: "99 99 99 99", dial_code: "+235 ", IsShowCustomFlag: false },
{ ISOCode: "TG", name: "Togo", FlagCssClass: "flag-tg", InputMasking: "99 99 99 99", dial_code: "+228 ", IsShowCustomFlag: false },
{ ISOCode: "TH", name: "Thailand", FlagCssClass: "flag-th", InputMasking: "999 999 9999", dial_code: "+66 ", IsShowCustomFlag: false },
{ ISOCode: "TJ", name: "Tajikistan", FlagCssClass: "flag-tj", InputMasking: "999 99 9999", dial_code: "+992 ", IsShowCustomFlag: false },
{ ISOCode: "TK", name: "Tokelau", FlagCssClass: "flag-tk", InputMasking: "9999", dial_code: "+690 ", IsShowCustomFlag: false },
{ ISOCode: "TL", name: "East Timor", FlagCssClass: "flag-tl", InputMasking: "9999 9999", dial_code: "+670 ", IsShowCustomFlag: false },
{ ISOCode: "TM", name: "Turkmenistan", FlagCssClass: "flag-tm", InputMasking: "9 99 999999", dial_code: "+993 ", IsShowCustomFlag: false },
{ ISOCode: "TN", name: "Tunisia", FlagCssClass: "flag-tn", InputMasking: "99 999 999", dial_code: "+216 ", IsShowCustomFlag: false },
{ ISOCode: "TO", name: "Tonga", FlagCssClass: "flag-to", InputMasking: "999 9999", dial_code: "+676 ", IsShowCustomFlag: false },
{ ISOCode: "TR", name: "Turkey", FlagCssClass: "flag-tr", InputMasking: "9999 999 99 99", dial_code: "+90 ", IsShowCustomFlag: false },
{ ISOCode: "TT", name: "Trinidad and Tobago", FlagCssClass: "flag-tt", InputMasking: "999-9999", dial_code: "+1 (868) ", IsShowCustomFlag: false },
{ ISOCode: "TV", name: "Tuvalu", FlagCssClass: "flag-tv", InputMasking: "999999", dial_code: "+688 ", IsShowCustomFlag: false },
{ ISOCode: "TW", name: "Taiwan", FlagCssClass: "flag-tw", InputMasking: "9999 999 999", dial_code: "+886 ", IsShowCustomFlag: false },
{ ISOCode: "TZ", name: "Tanzania", FlagCssClass: "flag-tz", InputMasking: "9999 999 999", dial_code: "+255 ", IsShowCustomFlag: false },
{ ISOCode: "UA", name: "Ukraine", FlagCssClass: "flag-ua", InputMasking: "999 999 9999", dial_code: "+380 ", IsShowCustomFlag: false },
{ ISOCode: "UG", name: "Uganda", FlagCssClass: "flag-ug", InputMasking: "9999 999999", dial_code: "+256 ", IsShowCustomFlag: false },
{ ISOCode: "US", name: "United States", FlagCssClass: "flag-us", InputMasking: "(999) 999-9999", dial_code: "+1 ", IsShowCustomFlag: false },
{ ISOCode: "UY", name: "Uruguay", FlagCssClass: "flag-uy", InputMasking: "999 999 999", dial_code: "+598 ", IsShowCustomFlag: false },
{ ISOCode: "UZ", name: "Uzbekistan", FlagCssClass: "flag-uz", InputMasking: "9 99 999 99 99", dial_code: "+998 ", IsShowCustomFlag: false },
{ ISOCode: "VA", name: "Vatican", FlagCssClass: "flag-va", InputMasking: "999 999 9999", dial_code: "+39 ", IsShowCustomFlag: false },
{ ISOCode: "VC", name: "Saint Vincent and the Grenadines", FlagCssClass: "flag-vc", InputMasking: "999-9999", dial_code: "+1 (784) ", IsShowCustomFlag: false },
{ ISOCode: "VE", name: "Venezuela", FlagCssClass: "flag-ve", InputMasking: "9999-9999999", dial_code: "+58 ", IsShowCustomFlag: false },
{ ISOCode: "VG", name: "British Virgin Islands", FlagCssClass: "flag-vg", InputMasking: "999-9999", dial_code: "+1 (284) ", IsShowCustomFlag: false },
{ ISOCode: "VI", name: "U.S. Virgin Islands", FlagCssClass: "flag-vi", InputMasking: "999-9999", dial_code: "+1 (340) ", IsShowCustomFlag: false },
{ ISOCode: "VN", name: "Vietnam", FlagCssClass: "flag-vn", InputMasking: "999 999 99 99", dial_code: "+84 ", IsShowCustomFlag: false },
{ ISOCode: "VU", name: "Vanuatu", FlagCssClass: "flag-vu", InputMasking: "999 9999", dial_code: "+678 ", IsShowCustomFlag: false },
{ ISOCode: "WF", name: "Wallis and Futuna", FlagCssClass: "flag-wf", InputMasking: "99 99 99", dial_code: "+681 ", IsShowCustomFlag: false },
{ ISOCode: "WS", name: "Samoa", FlagCssClass: "flag-ws", InputMasking: "99 99999", dial_code: "+685 ", IsShowCustomFlag: false },
{ ISOCode: "XK", name: "Kosovo", FlagCssClass: "flag-xk", InputMasking: "999 999 999", dial_code: "+383 ", IsShowCustomFlag: false },
{ ISOCode: "YE", name: "Yemen", FlagCssClass: "flag-ye", InputMasking: "9999 999 999", dial_code: "+967 ", IsShowCustomFlag: false },
{ ISOCode: "YT", name: "Mayotte", FlagCssClass: "flag-yt", InputMasking: "9999 99 99 99", dial_code: "+262 ", IsShowCustomFlag: false },
{ ISOCode: "ZA", name: "South Africa", FlagCssClass: "flag-za", InputMasking: "999 999 9999", dial_code: "+27 ", IsShowCustomFlag: false },
{ ISOCode: "ZM", name: "Zambia", FlagCssClass: "flag-zm", InputMasking: "999 9999999", dial_code: "+260 ", IsShowCustomFlag: false },
{ ISOCode: "ZW", name: "Zimbabwe", FlagCssClass: "flag-zw", InputMasking: "999 999 9999", dial_code: "+263 ", IsShowCustomFlag: false }
  ]
}